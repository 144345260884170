import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmService, HttpLoaderService, LoggingService, ReplaceOnEmptyPipe, SnackbarService, PrintOrDownloadTicketsComponent, localDateTimePipe } from '@tymes4-shared';
import { DialogHelper, DialogWidth, PageModule } from '@tymes4-shared';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { EditPaymentMethodComponent } from '../edit-payment-method/edit-payment-method.component';
import {
  ManualMailDialogComponent,
  ManualMailDialogParameters,
  ManualMailDialogResult
} from '../manual-mail-dialog/manual-mail-dialog.component';
import { PrintOrderHardcardsComponent } from '../print-order-hardcards/print-order-hardcards.component';
import { MailTemplateService, NewSalesEntityModificationArgs, OrderService, PendingOrderService, SalesEntityModificationService, SendMailETicketArgs, SendPaymentInvitationArgs, TicketDownloadArgs, TicketService, InvoiceBatchService, CreateInvoiceBatchByOrderIdsArgs, TicketPrintArgs, TicketSwapStatusEnum, PrintableTicket } from '../../api';
import {AuthService} from 'app/shared/services/auth.service';
import { ApplicationModuleHelperService } from '../../services/application-module.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  providers: [ReplaceOnEmptyPipe]
})
export class OrderDetailsComponent implements OnInit {

  static numberOfButtons = 0;
  static buttons = {
    Close: OrderDetailsComponent.numberOfButtons++,
    Edit: OrderDetailsComponent.numberOfButtons++,
    SendPaymentRequest: OrderDetailsComponent.numberOfButtons++,
    DownloadETickets: OrderDetailsComponent.numberOfButtons++,
    PrintOrderConfirmation: OrderDetailsComponent.numberOfButtons++,
    PrintInvoice: OrderDetailsComponent.numberOfButtons++,
    PrintTickets: OrderDetailsComponent.numberOfButtons++,
    PayByInvoice: OrderDetailsComponent.numberOfButtons++,
  };

  public form: FormGroup;
  public orderDetails: OrderDetails = null;
  // private billingCustomer = null;
  private editingObject = null;
  public secondaryTicketingActive: boolean = false;
  public saving = false;
  showPaymentDetails = true;

  selectedObjects = [];


  pagedHistoryLines = [];
  lastUsedPageHistory = null;
  orderPaymentHistory = null;

  public isReadOnly = false;
  public showButton = [];
  public isInArchiveMode = this.authService.isInArchiveMode();
  public isIbaActive: boolean = false;
  public isSecundaryShop: boolean = false;

  @ViewChild('paymentOverview') paymentOverview;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<OrderDetailsComponent>,
              private dialog: MatDialog,
              private apploader: HttpLoaderService,
              private pendingOrderService: PendingOrderService,
              private logging: LoggingService,
              private confirmService: ConfirmService,
              private ticketService: TicketService,
              private router: Router,
              private snackBar: SnackbarService,
              private salesEntityModificationService: SalesEntityModificationService,
              private orderService: OrderService,
              private mailTemplateService: MailTemplateService,
              private datePipe: DatePipe,
              private localDateTimePipe: localDateTimePipe,
              private replaceOnEmpty: ReplaceOnEmptyPipe,
              private invoiceBatchService: InvoiceBatchService,
              private translate: TranslateService,
              private authService: AuthService,
              private applicationModuleHelperService: ApplicationModuleHelperService) {
  }

  ngOnInit() {
    this.orderDetails = this.passedData.order;

    this.orderDetails.OrderLines.forEach(orderLine => {
      if(orderLine.TicketSwapStatus != null && (orderLine.TicketSwapStatus == TicketSwapStatusEnum.Offered || orderLine.TicketSwapStatus == TicketSwapStatusEnum.Sold)){
        this.secondaryTicketingActive = true;
        return;
      }
    })

    this.isSecundaryShop = this.orderDetails.HasSecondaryTicketStatus;
  
    if (this.passedData.isReadOnly)
      this.isReadOnly = true;

    for (const key in OrderDetailsComponent.buttons) {
      this.showButton[OrderDetailsComponent.buttons[key]] = true;
    }

    if (this.passedData.buttons) {
      for (const button in this.passedData.buttons) {
        this.showButton[button] = this.passedData.buttons[button];
      }
    }

    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    })
  }

  onTabChange(e) {

    if (e.selectedTab.id === 'payment-history') {
      this.paymentOverview.loadPaymentHistoryForOrder(this.orderDetails.Id);
    }

  }

  prolongSelectedOrder() {

    const orderId = this.orderDetails.Id;
    const order = null;
    const customer = null;


    //ask confirmation of the user

    this.confirmService.confirm({
      title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PROLONG.TITLE'),
      message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PROLONG.MSG')
    }).subscribe(confirmed => {
      if (confirmed) {

        this.pendingOrderService.createProlongationByOrderId(orderId).subscribe((pendingOrderId: any) => {
          if (pendingOrderId) {
            sessionStorage.setItem('pendingOrderId', pendingOrderId);
            this.dialogRef.close(false);
            this.router.navigate(['/sales/orders/sell-tickets']);
          } else {
            this.confirmService.confirm({
              title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PROLONG.NOT-POSSIBLE.TITLE'),
              message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PROLONG.NOT-POSSIBLE.MSG'),
              okonly: true
            }).subscribe(confirmed => {

            });
          }
        });

      }
    });

  }

  createInvoice() {

    const orderId = this.orderDetails.Id;

      this.confirmService.confirm({
        title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.DIRECT-INVOICE.TITLE'),
        message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.DIRECT-INVOICE.MSG')
      }).subscribe(confirmed => {
        if (confirmed) {

          this.apploader.open();

          var args : CreateInvoiceBatchByOrderIdsArgs ={
            OrderIds: [orderId]
          }

          this.invoiceBatchService.createInvoiceBatchByOrderIds(args).subscribe((invoicebatchId: number) => {

            // Batch direct factureren.
            this.invoiceBatchService.invoicing(invoicebatchId).subscribe(() => {
              this.dialogRef.close(null);
              this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.DIRECT-INVOICE-CREATED'));
              this.apploader.close();
            });

          });
        }

      });
  }

  editSelectedOrder() {


    const orderId = this.orderDetails.Id;

    this.orderService.hasActiveSecondaryOrder(orderId).subscribe((result: boolean) => {
      if (result) {
        this.confirmService.confirm({
          title: this.translate.instant('GENERIC.CONFIRM.ERROR.SECONDARY-TICKETS.TITLE'),
          message: this.translate.instant('GENERIC.CONFIRM.ERROR.SECONDARY-TICKETS.MSG'),
          okonly: true
        }).subscribe((result) => { })
      } else {
        if (this.orderDetails.PendingCancelByCustomer) {
          this.confirmService.confirm({
            okonly: true,
            title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.EDIT.TITLE'),
            message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.EDIT.MSG.NO-EDIT-SUPPORTER-CANCEL')
          }).subscribe(confirmed => {
          });
        } else {

          let message = this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.EDIT.MSG.BASE');
          if (this.orderDetails.PayByInvoice && this.orderDetails.Payed) {
            message = this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.EDIT.MSG.WARNING', {message: message});
          }

          this.confirmService.confirm({
            title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.EDIT.TITLE'),
            message: message
          }).subscribe(confirmed => {
            if (confirmed) {

              this.apploader.open();

              let args : NewSalesEntityModificationArgs = {
                OrderId : orderId,
              }

              this.salesEntityModificationService.addNew(args).subscribe((semId) => {
                this.dialogRef.close(null);
                this.apploader.close();
                this.router.navigate(['/sales/edit-sales']);
              });
            }

          });
        }
      }
    });
  }

  toggleSelection(orderLine) {

    if (orderLine == null) return;

    if (this.isItemSelected(orderLine)) {
      //remove from the selection
      const index = this.selectedObjects.indexOf(orderLine);
      this.selectedObjects.splice(index, 1);
    } else {
      this.selectedObjects.push(orderLine);
    }
  }

  isItemSelected(event) {

    if (!event) return null;

    if (this.selectedObjects == null && this.selectedObjects.length == 0)
      return false;

    const found = this.selectedObjects.filter(function (ep) {
      if (ep.Id == event.Id) return ep;
    });

    return (found != null && found.length > 0);
  }

  clearSelection() {
    this.selectedObjects = [];
  }


  calculateTotal(property) {
    let result = 0;

    for (const ol of this.orderDetails.OrderLines) {
      result += ol[property];
    }

    return result;
  }


  displayHardcardDialog() {


    const title = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-HARDCARDS.TITLE');
    const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrderHardcardsComponent, {
      width: '1024px',
      disableClose: true,
      data: {title: title, payload: this.orderDetails}
    });
    dialogRef.afterClosed()
      .subscribe(submitForm => {

          // if (submitForm === null) {
          //   //nothing to do. The download is closed without pressing download
          // }
          // else {

          //   this.apploader.open();

          //   //to the download
          //   this.ticketService.download(this.orderDetails.Id, submitForm.eventId === -1 ? null : submitForm.eventId, submitForm.SalesChannelId).subscribe((data: any) => {
          //     this.apploader.close();

          //   //  console.log("download data received OK.");
          //     FileSaver.saveAs(data, `E-tickets_order_${this.orderDetails.Id}.zip`);
          //   });

          // }
        }
      );


    return false;

  }

  displayPrintDialog() {

    this.apploader.open();

    this.orderService.getOrderPrintDetails(this.orderDetails.Id).subscribe((data:any) => {

      this.apploader.close();

      const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.lg, {
        title: this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-TICKETS.TITLE'),
        payload: data, mode: 'print', isIbaActive: this.isIbaActive
      });

      const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrDownloadTicketsComponent, dialogOptions);


      dialogRef.afterClosed()
        .subscribe(submitForm => {

            if (submitForm === null) {
              //nothing to do. The download is closed without pressing download/print
            } else {

              this.apploader.open();

              var args : TicketPrintArgs = {
                OrderId: this.orderDetails.Id,
                SelectedEventPlacementIds: submitForm.SelectedEventplacementIds,
                PackagingSlipOption: submitForm.packagingSlip,
                CutMode: submitForm.cutMode,
                isDuplicate: false,
                DuplicationReasonId: submitForm.DuplicationReasonId,
              }

              //to the download
              this.ticketService.printTicket(args).subscribe((data: any) => {
                this.apploader.close();
                this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.PRINTJOB-CREATED'), 'GENERIC.SNACKBAR.CLOSE');
              }, error => {

                this.apploader.close();

                this.confirmService.confirm({
                  title: this.translate.instant('GENERIC.SNACKBAR.SOMETHING-WENT-WRONG'),
                  message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PRINT-TICKETS.FAILED.MSG'),
                  okonly: true
                }).subscribe((result) => {
                  this.dialogRef.close(false);
                });
              });
            }
          }
        );
    });

    return false;
  }

  displayDownloadDialog() {
    this.apploader.open();
    setTimeout(() => {
    this.orderService.getOrderPrintDetails(this.orderDetails.Id).subscribe((data:any) => {

      this.apploader.close();

      const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.lg, {
        title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.DOWNLOAD-ETICKETS.TITLE'),
        payload: data, mode: 'download', allowSalesChannelSelect: true, isIbaActive: this.isIbaActive
      });

        const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrDownloadTicketsComponent, dialogOptions);


      dialogRef.afterClosed()
        .subscribe(submitForm => {

            if (submitForm === null) {
              //nothing to do. The download is closed without pressing download
            } else {

              this.apploader.open();

              var args: TicketDownloadArgs ={
                OrderId: this.orderDetails.Id,
                SelectedEventPlacementIds: submitForm.SelectedEventplacementIds,
                SalesChannelId: submitForm.SalesChannelId,
                isDuplicate: false,
                DuplicationReasonId: submitForm.duplicationReasonId
              }

              //to the download
              this.ticketService.downloadTicket(args).subscribe((data: any) => {
                this.apploader.close();

                //  console.log("download data received OK.");
                FileSaver.saveAs(data, `E-tickets_order_${this.orderDetails.Id}.zip`);
              });

            }
          }
        );
      });
    });

    return false;

  }

  displayEmailDialog() {
    let data = <ManualMailDialogParameters>{};
    data.title = 'ORDER.DETAILS.TICKETS.SEND-TICKETS.TITLE';
    data.email = this.orderDetails.CustomerEmail;
    data.showSalesChannelSelection = true;

    this.apploader.open();

    this.orderService.getOrderPrintDetails(this.orderDetails.Id).subscribe(printDetails => {
      data.attachments = printDetails.PrintableTickets;
      data.salesChannels = printDetails.ETicketPrintableChannels;

      this.mailTemplateService.getConfirmationMail(this.orderDetails.Id).subscribe((result) => {
        this.apploader.close();
        data.body = result.Body;
        data.subject = result.Subject;

        const dialogRef: MatDialogRef<any> = this.dialog.open(ManualMailDialogComponent, {
          width: '720px',
          disableClose: true,
          data: data
        });

        dialogRef.afterClosed()
          .subscribe((result: ManualMailDialogResult) => {
              if (result) {
                this.apploader.open();

                var args: SendMailETicketArgs = {
                  Email: result.email,
                  Subject: result.subject,
                  Body: result.body,
                  SalesChannelId: result.salesChannelId,
                  EventPlacementIds: result.attachments
                }

                this.orderService.sendETicketMail(this.orderDetails.Id, args).subscribe((a) => {
                  this.apploader.close();
                  this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.EMAIL-HAS-BEEN-SENT'), 'GENERIC.SNACKBAR.CLOSE');
                  console.log(a);
                });
              }
            }
          );
      });
    });

    return false;
  }

  printPackagingSlip() {

    this.apploader.open();

    this.orderService.getPackagingSlip(this.orderDetails.Id).subscribe((pdf: any) => {
      FileSaver.saveAs(pdf, `order_confirmation_${this.orderDetails.Id}.pdf`);
      this.apploader.close();
    });

  }

  printInvoice() {
    this.apploader.open();

    this.orderService.getInvoice(this.orderDetails.Id).subscribe((pdf: any) => {
      FileSaver.saveAs(pdf, `invoice_${this.orderDetails.Id}.pdf`);
      this.apploader.close();
    });
  }


  initiatePayPerEmail() {

    const amountToRequest = (this.orderDetails.Balance - this.orderDetails.PendingPaymentBalance).toFixed(2);

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.SEND-PAY-PER-MAIL.MSG', {amount: amountToRequest, primaryContactEmail: this.orderDetails.PrimaryContactEmail})
    }).subscribe(confirmed => {
      if (confirmed) {

        this.apploader.open();

        var args : SendPaymentInvitationArgs = {
          Email: this.orderDetails.PrimaryContactEmail
        }

        this.orderService.sendPaymentRequest(this.orderDetails.Id, args).subscribe((ok: boolean) => {
          this.apploader.close();
          if (ok === true) {
            this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.SEND-PAY-PER-MAIL.SUCCESS'), 'GENERIC.SNACKBAR.CLOSE');
            this.paymentOverview.loadPaymentHistoryForOrder(this.orderDetails.Id);
          } else {
            this.confirmService.confirm({
              title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.SEND-PAY-PER-MAIL.FAILED.TITLE'),
              message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.SEND-PAY-PER-MAIL.FAILED.MSG'),
              okonly: true
            }).subscribe((confirmed: boolean) => {
            });
          }
        });

      }
    });

  }

  getButtons() {
    return OrderDetailsComponent.buttons;
  }

  public toggleInvoice() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(EditPaymentMethodComponent, {
      disableClose: false,
      data: {title: this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.EDIT.PAYMENT-METHOD.TITLE'), payload: {payByInvoice: this.orderDetails.PayByInvoice}}
    });

    let hasPaymentInvitationObservable: Observable<Object>;
    dialogRef.afterOpened().subscribe(() => {
      hasPaymentInvitationObservable = this.orderService.hasPaymentInvitations(this.orderDetails.Id);
      hasPaymentInvitationObservable.subscribe(r => {
        hasPaymentInvitationObservable = Observable.create((observer) => {
          observer.next(r);
          observer.complete();
        });
      });
    });

    dialogRef.afterClosed().subscribe(ret => {
      if (ret !== null) {
        const setInvoice = (val) => this.orderService.patchInvoicing(this.orderDetails.Id, val).subscribe((payByInvoice: boolean) => {
          this.orderDetails.PayByInvoice = payByInvoice;
        });
        hasPaymentInvitationObservable.subscribe((hasInvitations) => {
          if (!hasInvitations) {
            setInvoice(ret);
          } else {
            this.confirmService.confirm({
              title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
              message: this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.EDIT.PAYMENT-INVITATION.WARNING')
            }).subscribe((result) => {
              if (result) {
                setInvoice(ret);
              }
            });
          }
        });
      }
    });

  }
}

export interface OrderDetails {
  AllowEticketPrinting: boolean;
  AllowEticketMailing: boolean;
  AllowHardcardPrinting: boolean;
  AllowSoftcard: boolean;
  AmountPayed: number;
  Balance: number;
  PendingPaymentBalance: number;
  Payed: true;
  Id: number;
  PrimaryContactEmail: string;
  UsedPaymentMethods: string;
  OrderLines: Array<any>;
  OrderDate: string;
  Reference: string;
  TotalInclVat: number;
  SubscriptionViews: Array<any>;
  HasProlongationLines: boolean;
  HasSecondaryTicketStatus: boolean;
  PayPerMailAllowed: boolean;
  Locked: boolean;
  DeliveryMethodId: number;
  DeliveryMethodCode: string;
  DeliveryMethodName: string;
  PayByInvoice: boolean;
  SalesChannelName: string;
  SalesChannelId: number;
  CustomerId: number;
  CustomerName: string;
  CustomerEmail: string;
  OrderAddressLine1: string;
  OrderAddressPostalCode: string;
  OrderAddressCity: string;
  PendingCancelByCustomer: boolean;
  CanProlongOrder: boolean;
  Deleted: string;
  DiscountDisplayName: string;
  DiscountCodeId: number;
  ProductsSubTotalAmount: number;
  PaymentMethodSubTotalAmount: number;
  DeliveryMethodSubTotalAmount: number;
  DiscountSubTotalAmount: number;
  DiscountTotals: Array<any>;
  IsInvoiced: boolean;
}
