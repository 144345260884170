

<div class="row modal-bar">
    <div class="col-sm-9">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-3">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(null);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>


  <t4-page-warning *ngIf="selectedSalesChannelMissesTemplates === true" warning='T4.PRINTORDOWNLOADTICKETS.NOTEMPLATEMSG'></t4-page-warning>


<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">

      <div class="form-layout">


        <div class="row" *ngIf="dialogMode !=='print' && allowSalesChannelSelect"> <!--eticket-->
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="form-control-label">{{'T4.PRINTORDOWNLOADTICKETS.USETEMPLATESOF' | translate}}:</label>
                    <ng-select  [items]="selectableSalesChannels" bindLabel="ChannelName" bindValue="Id" placeholder="{{'T4.GENERIC.SALESCHANNEL' | translate}}" formControlName="SalesChannelId"  (change)="handleSalesChannelChange($event)"
                                [clearable]="false" notFoundText="" appendTo="body"></ng-select>
                </div>
            </div>
        </div>

        <div class="row d-none">  <!-- HIDDEN, reenable when functionality is done -->
            <div class="col-lg-12">
                <div class="form-group">
                    <input type="checkbox" name="Reprint" (change)="onReprintChecked($event)" formControlName="IsDuplicate" class="mr-2"><span>Herprint (Nieuw barcodes)</span>
                </div>
            </div>

        </div>
        <div class="row d-none">  <!-- HIDDEN, reenable when functionality is done: [class.d-none]="reprintChecked === false" -->
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="form-control-label">Reden herprint:</label>
                    <ng-select  [items]="duplicationReasons" bindLabel="Name" bindValue="Id" placeholder="Reden herprint" formControlName="DuplicationReasonId"
                                [clearable]="false" notFoundText="" appendTo="body"></ng-select>
                </div>
            </div>

        </div>

        <div class="row" *ngIf="dialogMode==='print'">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="form-control-label">{{'T4.PRINTORDOWNLOADTICKETS.CUTMODE' | translate}}</label>
                    <ng-select [items]="selectableCutModes" bindLabel="Name" bindValue="Id" placeholder="{{'T4.PRINTORDOWNLOADTICKETS.CUTMODE' | translate}}" formControlName="cutMode"
                               [clearable]="false" notFoundText="{{'GENERIC.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="dialogMode==='print'">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="form-control-label">{{'T4.PRINTORDOWNLOADTICKETS.PACKAGINGSLIP' | translate}}:</label>
                    <ng-select [items]="selectablePackagingSlipOptions" bindLabel="Name" bindValue="Id" placeholder="{{'T4.PRINTORDOWNLOADTICKETS.PACKAGINGSLIP' | translate}}" formControlName="packagingSlip"
                               [clearable]="false" notFoundText="{{'GENERIC.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">

                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-9">
                            <label class="form-control-label">{{'T4.PRINTORDOWNLOADTICKETS.SELECTTICKETS' | translate}}:</label>
                        </div>
                        <div class="col-lg-3 text-right">
                            <span class="badge badge-primary">{{ ticketsSelected }} / {{ ticketsTotal }}</span>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="rounded table text-dark table-striped clickable">

                            <thead>
                            <th><input type="checkbox" [checked]="isAnySelectable" (click)="allSelectionToggle($event.currentTarget.checked)" /></th>
                            <th>{{'T4.GENERIC.EVENT' | translate}}</th>
                            <th>{{'T4.GENERIC.INTHENAMEOF' | translate}}</th>
                            <th>{{'T4.GENERIC.SECTIONROWSEAT' | translate}}</th>
                            <th *ngIf="secondaryTicketingActive">{{'T4.GENERIC.SECONDARY_TICKETING_STATUS' | translate}}</th>
                            <th colspan="4"></th>
                            </thead>

                            <tbody>
                            <ng-container *ngFor="let item of pagedPrintableTickets">
                                <tr (click)="toggleSelection(item)">
                                    <td class=""><input type="checkbox" [checked]="isItemSelected(item)" /> </td>
                                    <td>{{ item.EventStartDate | localDateTime }} - {{item.EventName}} </td>
                                    <td><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="item.IdentificationCustomerModel"></t4-customer-identification-control></td>
                                    <td>{{ item.Ticketname}} / {{item.Row  | replaceOnEmpty}} / {{item.Seat | replaceOnEmpty}}</td>
                                    <td *ngIf="secondaryTicketingActive"><span [ngClass]="getTicketSwapStatusClass(item.TicketSwapStatus)">{{getTicketSwapStatus(item.TicketSwapStatus) | translate}}</span></td>
                                    <td style="width: 40px;" [class.inactive]="item.LastBarcodeViewed === null" data-toggle="tooltip" title="{{ item.LastBarcodeViewed === null ? '' : ConstructLastBarcodeToolTip(item.LastBarcodeViewed) }}"><i class="fas fa-barcode"></i></td>
                                    <td style="width: 40px;" [class.inactive]="item.LastDuplication === null" data-toggle="tooltip" title="{{ item.LastDuplication === null ? '' : ConstructLastDuplicationToolTip(item.LastDuplication) }}"><i class="fas fa-copy"></i></td>
                                    <td style="width: 40px;" [class.inactive]="item.LastPrintSoftcard === null" data-toggle="tooltip" title="{{ item.LastPrintSoftcard === null ? '' : ConstructLastPrintToolTip(item.LastPrintSoftcard) }}"><i class="fas fa-print"></i></td>
                                    <td style="width: 40px;" [class.inactive]="item.LastPrintEticket === null" data-toggle="tooltip" title="{{ item.LastPrintEticket === null ? '' : ConstructLastDownloadToolTip(item.LastPrintEticket) }}"><i class="fas fa-download"></i></td>
                                </tr>
                            </ng-container> 
                            
                            <ng-container  *ngIf="!pagedPrintableTickets || pagedPrintableTickets.length===0" >
                                <tr>
                                    <td colspan= 6> {{'GENERIC.NOITEMSFOUND' | translate}} 
                                        <!-- Er zijn geen kaarten gevonden -->
                                    </td>
                                </tr>    
                            </ng-container>
                            </tbody>
                        </table>
                    </div>

                    <t4-pager [listCounts]="false" (onPage)="doPage($event)" #pagerComponent></t4-pager>

                </div>


            </div>
        </div>

        <div class="form-layout-footer row">
            <div class="col-12">
                <button class="btn btn-block btn-primary bd-0" (click)="doDownload()" [disabled]="form.invalid || saving || selectedSalesChannelMissesTemplates === true || selectedObjects.length == 0" *ngIf="dialogMode==='download'">
                    <i class="fas fa-download"></i><span>{{'BTN.DOWNLOAD' | translate}}</span>
                </button>
            </div>
            <div class="col-12">
                <button class="btn btn-block btn-primary bd-0" (click)="doDownload()" [disabled]="form.invalid || saving || selectedSalesChannelMissesTemplates === true || selectedObjects.length == 0" title="Printen." *ngIf="dialogMode==='print'">
                    <i class="fas fa-print"></i><span>{{'BTN.PRINT' | translate}}</span></button>
            </div>
        </div><!-- form-layout-footer -->


      </div><!-- form-layout -->
    </div>
  </form>


