import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ConfirmService, HttpLoaderService } from '@tymes4-shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OrderService, PendingOrderService, PlacePendingOrderArgs } from '../../api';

@Component({
  selector: 'app-confirm-order-modification',
  templateUrl: './confirm-order-modification.component.html',
  styleUrls: ['./confirm-order-modification.component.scss']
})
export class ConfirmOrderModificationComponent implements OnInit {

  public saving = false;
  public form: FormGroup;
  public order = null;
  public pendingOrderId: number = null;
  public deliveryMethods = null;
  public salesChannels = null;
  public showCreditQuestion: boolean = false;
  public selectedPriceVariant = null;
  private pendingOrderDetails = null;
  private pendingActions = null;

  constructor(private loader: HttpLoaderService,
    private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private pendingOrderService: PendingOrderService,
    private confirmService: ConfirmService,
    public dialogRef: MatDialogRef<ConfirmOrderModificationComponent>,
    private translate: TranslateService) { }

  ngOnInit() {
    let orderId = this.passedData.orderId;
    this.pendingOrderId = this.passedData.pendingOrderId;

    this.form = new FormGroup({
      OrderId: new FormControl(''), //Passed to preserve value
      Reference: new FormControl(''),
      DeliveryMethodId: new FormControl(''),
      SalesChannelId: new FormControl(''),
      PayByInvoice: new FormControl(''), // Passed to preserve value
      AccountManagerId: new FormControl(''), // Passed to preserve value
      CreateCreditInvoice: new FormControl('')
    });

    this.loader.open();

    const call0 = this.orderService.getOrderById(orderId);
    const call1 = this.pendingOrderService.getDeliveryMethods(this.pendingOrderId);
    const call2 = this.pendingOrderService.getSalesChannelsForModification(orderId);

    const call3 = this.pendingOrderService.getPendingOrderDetails(this.pendingOrderId);
    const call4 = this.pendingOrderService.getPendingActions(this.pendingOrderId);

    forkJoin([call0, call1, call2, call3, call4]).subscribe(([order, deliveryMethods, salesChannels, pendingOrder, pendingActions]) => {
      this.order = order;

      const o = {
        OrderId: order.Id,
        PendingOrderId: this.pendingOrderId,
        Reference: order.Reference,
        PayByInvoice: order.PayByInvoice,
        AccountManagerId: order.AccountManagerId,
        DeliveryMethodId: order.DeliveryMethodId,
        SalesChannelId: order.SalesChannelId
      };

      this.form.patchValue(o);
      this.deliveryMethods = deliveryMethods;

      this.salesChannels = salesChannels;

      this.loader.close();

      this.pendingOrderDetails = pendingOrder;
      this.pendingActions = pendingActions;

      this.checkCreditQuestion();
    });
  }

  /// Method for checking if we need to show the options for auto creating a credit invoice when changing an existing order.
  checkCreditQuestion() {
    // Check if the changes made to the order require creating one or more credit and/or debit invoice(s).
    if (this.order.PayByInvoice === true && this.order.Payed === true) {

      this.showCreditQuestion = true;

      const creditInvoiceRequired = this.pendingActions.Lines.some(x => x.IsCreditInvoiceRequired === true);

      // Als er wijzigingen in product en/of bedrag zijn, is het verplicht een credit factuur aan te maken.
      if (creditInvoiceRequired) {
        // Disablen van radio input als bovenstaande conditie.
        this.form.controls['CreateCreditInvoice'].setValue('1');
        this.form.controls['CreateCreditInvoice'].disable();
      }
    }
  }

  submit() {
    this.saving = true;
    var o = this.form.value;
    this.placeOrder(o);
  }

  priceVariationChange(e) {
    this.selectedPriceVariant = e.value;
  }
  placeOrder(order: any) {
    if (this.form.value.CreateCreditInvoice === '' && this.showCreditQuestion) {
      this.confirmService.okOnlyConfirm(this.translate.instant('DIALOGS.CONFIRM-ORDER-MODIFICATION.CONFIRM.TITLE.MAKE-YOUR-CHOICE'), this.translate.instant('DIALOGS.CONFIRM-ORDER-MODIFICATION.CONFIRM.MESSAGE.CREDIT-EXISTING-INVOICE-IS-REQUIRED')).subscribe();
      this.saving = false;
      return;
    }

    this.loader.open();

    var orderLinePrices = null; //orderline prices cannot be edited here

    // Property kan undefined zijn, omdat deze disabled kan zijn en dan dus niet meekomt in het formulier.
    var createCreditInvoice = this.form.value.CreateCreditInvoice === undefined || this.form.value.CreateCreditInvoice === '1';

    var args : PlacePendingOrderArgs = {
      PendingOrderId: this.pendingOrderId,
      Reference: order.Reference,
      OrderLinePrices: order.OrderLinePrices,
      OrderLineSubscriptions: null,
      PayByInvoice: order.PayByInvoice,
      AccountManagerId: order.AccountManagerId,
      DeliveryMethodId: order.DeliveryMethodId,
      SalesChannelId: order.SalesChannelId,
      IncludeInInvoiceBatch: order.IncludeInInvoiceBatch,
      CreateCreditInvoice: createCreditInvoice
    }

    this.pendingOrderService.placeOrder(args).subscribe((result: any) => {
        this.loader.close();
        this.dialogRef.close(result);
      });
  }
}
