import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { ApplicationModuleService } from "../api/api/applicationModule.service";

export const ApplicationModules = {
  FanManagement: 'fanmanagement',
  TicketShop: 'ticketShop',
  Distributix: 'distributix',
  MobileTicketing: 'mobileticketing',
  SecondaryTicketing: 'secondaryticketing',
  Merchandise: 'merchandise',
  MergeCustomers: 'mergecustomers',
  MatchDayPass: 'matchdaypass',
  RecurringMemberships: 'recurringmemberships',
  LoyaltyTag: 'loyaltytag',
  ExternalTickets: 'externaltickets',
  RulesEngine: 'rulesengine',
  DashboardVideo: 'dashboardvideo',
  IBA: 'iba',
  CustomTheming: 'customtheming'
};

@Injectable()
export class ApplicationModuleHelperService  {

  public readonly activeModulesKey = 'active_modules';

  constructor(private applicationModuleService: ApplicationModuleService) {
  }


  public getActiveModules(): Observable<Array<string>> {
    return new Observable((observer) => {
        let modulesJson: string = sessionStorage.getItem(this.activeModulesKey);
        let modules: Array<string>;
        if (modulesJson === null) {
          this.applicationModuleService.getApplicationModules().subscribe((result) => {
            modules = result.map((val) => val.toLowerCase());
            modulesJson = JSON.stringify(modules);
            sessionStorage.setItem(this.activeModulesKey, modulesJson);
            observer.next(modules);
            observer.complete();
          });
        } else {
          modules = JSON.parse(modulesJson);
          observer.next(modules);
          observer.complete();
        }
      }
    );
  }


}
