import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SalesUnplacedTicketSelectionComponent } from '../../components/ticket-sales/sales-unplaced-ticket-selection/sales-unplaced-ticket-selection.component';
import { OrderLine } from '../../models/orderline';
import { SeatPlanComponent } from 'app/shared/components/seat-plan/seat-plan.component';
import { PlacementLine } from '../../models/placement-line';

@Component({
  selector: 'app-sales-section-details',
  templateUrl: './sales-section-details.component.html',
  styleUrls: ['./sales-section-details.component.scss']
})
export class SalesSectionDetailsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef:  MatDialogRef<SalesSectionDetailsComponent>,
              private translate: TranslateService) { }


  @ViewChild('unplacedTicketSelection') unplacedTicketListComponents: SalesUnplacedTicketSelectionComponent;
  @ViewChildren(SeatPlanComponent) seatPlanComponents: QueryList<SeatPlanComponent>

  orderLinesPerTicketType: PlacementLine[] = [];
  selectedTabIdx = 0;
  tabDetails = [];
  selectedSellableSection: any = {};
  selectedEvents: any = [];
  selectedEventIds: number[];
  selectedPassePartouts: any[];
  ticketTypes = [];
  placedTicketTypes = [];
  selectionIsEmpty = true;
  selectedOrderLines = [];

  ngOnInit() {

    this.selectedPassePartouts = this.passedData.payload.selectedPassePartouts;
    this.selectedSellableSection = this.passedData.payload.section;
    this.ticketTypes = this.passedData.payload.ticketTypes;
    this.selectedEvents = this.passedData.payload.selectedEvents;
    this.selectedEventIds = this.passedData.payload.selectedEventIds;

    this.placedTicketTypes = this.ticketTypes.filter(function(tt){
      if (tt.IsPlaced == true) return tt;
    });

    this.ticketTypes.forEach(element => {
      this.tabDetails.push ({
        ticketTypeId: element.Id
      });

    });

    this.selectedOrderLines =  this.filterOrderLinesOnCurrentSection(this.passedData.payload.selectedOrderLines);
  }

  filterOrderLinesOnCurrentSection(orderLines) {

    if (orderLines == null || orderLines.length == 0) return;

    var vbbId = this.selectedSellableSection.VenueBuildingBlockId;
    var groupingId = this.selectedSellableSection.GroupingId;

    //found all orderlines for the section
    var ols = orderLines.filter(function(ol) {
        if (ol.ParentVenueBuildingBlockId == vbbId && ol.GroupingId == groupingId) return ol;
      });

      return ols;
  }

  onPlacementSelectionChange(selectedEndPoints, ticketTypeId) {

    var s = this.orderLinesPerTicketType.filter(function(t){ if (t.TicketTypeId == ticketTypeId) return t; });

    let placementLine: any = null;

    if (s != null && s.length > 0) {
      placementLine = s[0];
    }
    else {
      //create a new one
      placementLine = new PlacementLine();
      placementLine.TicketTypeId = ticketTypeId;
      this.orderLinesPerTicketType.push(placementLine);
    }

    //make a new array of order lines
    placementLine.OrderLines = new Array<OrderLine>();

    for (let ep of selectedEndPoints) {

      let line = new OrderLine();
      line.Column = ep.Column;
      line.Row = ep.Row;
      line.NumberOfTickets = 1;
      line.TicketTypeId = ticketTypeId;
      line.EventPlacementId = this.selectedSellableSection.Id;
      placementLine.OrderLines.push (line);
    }
    

    this.updateIsEmpty();
  }

  onUnplacementSelectionChange(amountsPerContingentAndVariant) {

    for (let apt of amountsPerContingentAndVariant) {

      var s = this.orderLinesPerTicketType.filter(function(t){ if (t.TicketTypeId == apt.ticketTypeId && t.PriceVariantId == apt.priceVariantId && t.ContingentId == apt.contingentId) return t; });

      let placementLine: any = null;

      if (s != null && s.length > 0) {
        placementLine = s[0];
      }
      else {
        //create a new one
        placementLine = new PlacementLine();
        placementLine.TicketTypeId = apt.ticketTypeId,
        placementLine.ContingentId = apt.contingentId as number,
        placementLine.PriceVariantId = apt.priceVariantId
        this.orderLinesPerTicketType.push(placementLine);
      }
    
      //make a new array of order lines
      placementLine.OrderLines = new Array<OrderLine>();

      let line = new OrderLine();
      line.Column = null;
      line.Row = null;
      line.NumberOfTickets = apt.selectedAmount;
      line.TicketTypeId = apt.ticketTypeId;
      line.EventPlacementId = this.selectedSellableSection.Id;
      line.PriceVariantId = apt.priceVariantId;
      line.ContingentId = apt.contingentId;

      placementLine.OrderLines.push (line);
      
    }

    this.updateIsEmpty();


  }

  updateIsEmpty () {
    this.selectionIsEmpty = true;

    for (let s of this.orderLinesPerTicketType) {
      if (s.OrderLines != null && s.OrderLines.length > 0) {
        this.selectionIsEmpty =  false;
        break;
      }
    }
  }
  submit() {

    
    this.dialogRef.close(this.orderLinesPerTicketType);
  }

  getTicketTypeTabelLabel(ticketType) {
    let count = this.getFreeTicketCount(ticketType.Id);
    return `${ticketType.Name} (${count})`;
  }

  getFreeTicketCount(ticketTypeId){

      var c = this.selectedSellableSection.TicketsPerTicketTypeId.filter(function(tptt){ if (tptt.Id == ticketTypeId) { return  tptt}});

      if (c != null && c.length > 0) {
        return c[0].Value;
      }

      return 0;
  }

  getTicketTypeIsAvailable(ticketTypeId) {
    var c = this.selectedSellableSection.TicketsPerTicketTypeId.filter(function(tptt){ if (tptt.Id == ticketTypeId) { return  tptt}});

    if (c != null && c.length > 0) {
      return true;
    }

    return false;
  }

  availableTicketTypes() {
    let availableTT: any[] = [];
    for (let i = 0; i < this.placedTicketTypes.length; i++) {
      if (this.getTicketTypeIsAvailable(this.placedTicketTypes[i].Id)) {
        availableTT.push(this.placedTicketTypes[i]);
      }
    }
    return availableTT;
  }

  getEventSelectionSummary () {
    let restCount = 0;
    let idx = 0;
    let result = '';
    let displayCount = 3;

    if (this.selectedPassePartouts != null && this.selectedPassePartouts.length > 0) {
      //display passepartouts
      for (let se of this.selectedPassePartouts) {
        idx++;

        if (idx <= displayCount) {
          if (idx > 1) result += ', ';
          result += se.Name;
        }
        else {
          restCount ++;
        }
      }
    }
    else {
      //display events
      for (let se of this.selectedEvents) {
        idx++;

        if (idx <= displayCount) {
          if (idx > 1) result += ', ';
          result += se.Name;
        }
        else {
          restCount ++;
        }
      }
    }

    if (restCount > 0) {
      result += this.translate.instant('SALES.ORDERS.NEW.SALES-SECTION-SELECTION.DIALOG.DETAILS.AND-OTHER', {restCount: restCount});
    }

    return result;
  }

  loadTabDetails(event) {
    const idx = event.index;
    if (idx >= this.tabDetails.length) return;

    this.selectedTabIdx = idx;
    if (this.seatPlanComponents !== undefined) {
      for (let seatPlan of this.seatPlanComponents.toArray()) {
          seatPlan.initializeSelectable();
      }
    }
  }
}
