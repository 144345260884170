import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ListViewModule } from './list-view.module';
import { SubscriptionLinesComponent } from './../components/order-details/subscription-lines/subscription-lines.component';
import { OrderLinesComponent } from './../components/order-details/order-lines/order-lines.component';
import { DiscountLinesComponent } from './../components/order-details/discount-lines/discount-lines.component';
import { OrderHeaderComponent } from '../components/order-details/order-header/order-header.component';
import { PagerModule } from './pager.module';
import { PageModule } from './page.module';
import { PrintOrDownloadTicketsComponent } from '../components/order-details/print-or-download-tickets/print-or-download-tickets.component';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { ControlsModule } from './controls.module';
import { TicketIBAStatusComponent } from '../components/ticket-iba-status/ticket-iba-status.component';


const classesToInclude = [
    PrintOrDownloadTicketsComponent,
    OrderHeaderComponent,
    OrderLinesComponent,
    DiscountLinesComponent,
    SubscriptionLinesComponent, 
    TicketIBAStatusComponent
]

@NgModule({
    declarations: classesToInclude,
    imports: [
        CommonModule, 
        MatDialogModule, 
        PageModule,
        FormsModule,
        ReactiveFormsModule,
        PagerModule,
        PipesModule,
        NgSelectModule,
        MatSelectModule,
        ListViewModule,
        TranslateModule,
        ControlsModule,
        
    ],
    exports: classesToInclude,
    providers: [
        TranslateService
    ],
    entryComponents: [PrintOrDownloadTicketsComponent]
})
export class OrderDetailsModule {}