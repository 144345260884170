import { PagerComponent } from './../pager/pager.component';
import {HttpResponse} from '@angular/common/http';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  AfterViewInit
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {ImportantItemFilterDirective} from '../../directives/list-view/important-item-filter.directive';
import {ItemFilterDirective} from '../../directives/list-view/item-filter.directive';
import {ListActionsDirective} from '../../directives/list-view/list-actions.directive';
import {ListAdditionalContentDirective} from '../../directives/list-view/list-additional-content-directive';
import {ListItemDirective} from '../../directives/list-view/list-item-directive';
import {CustomContentDirective} from '../../directives/list-view/custom-content-directive';
import {ListViewSelectionMode} from '../../enums/list-view-selection-mode.enum';
import {SearchDataRequest} from '../../models/http/search-data-request';
import {HttpLoaderService} from '../../services/http-loader.service';
import {LoggingService} from '../../services/logging.service';
import { PagedResult } from '../../models/list-view/pagedResult';
import { ListViewHelper } from '../../helpers/list-view.helper';
import { ListBannerDirective } from '../../directives/list-view/list-banner.directive';

interface FilterValues {
  kvp: any;
}

export interface ListOptions {
  headers: any[];
  dataRequestMethod?: (SearchText?: string, Page?: number, PerPage?: number, Sort?: string, Ascending?: boolean, Filters?: { [key: string]: string; }, ParentId?: number) => Observable<PagedResult>;
  pageSize?: number;
  search?: boolean;
  showBarcodeSearch?: boolean;
  disableSort?: boolean;
  sortDesc?: boolean;
  filterRequired?: boolean;
  selectionMode?: ListViewSelectionMode;
  listCounts?: boolean;

  fixedTableLayout?: boolean;
  noItemsText?: string;
  actionDropdownWidth?: string;
  noSpacing?: boolean;
  renderInCard?: boolean;

  staticFilters?: any;
  importantFilterValues?: any;

  customHtml?: string;
  showCustomData?: boolean;


}

@Component({
  selector: 't4-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnInit, AfterViewInit {

  public filterValues: FilterValues = {kvp: {}};
  public importantFilterValues: FilterValues = {kvp: {}};
  public staticFilterValues: FilterValues = {kvp: {}};

  public isLoading = true;
  public form: FormGroup;
  public dataRequestMethod: (SearchText?: string, Page?: number, PerPage?: number, Sort?: string, Ascending?: boolean, Filters?: { [key: string]: string; }, ParentId?: number) => Observable<PagedResult>;

  // options
  public customData: boolean = false;
  public customHtml: string = '';
  public pageSize = 12;
  public displaySearch = false;
  public noItemText = 'GENERIC.NOITEMSFOUND';
  public resultsCapped = false;

  // public multiSelectMode = false;
  // public disableSelect = false;
  public searchPerformed: boolean = false;
  public filterRequired: boolean = false;
  public selectionMode: ListViewSelectionMode;
  public disableSort = false;
  public dataItems: any[] = [];
  public totalObjectCount: number;
  public searchText: string;
  private pageNr: number;
  public actionDropdownWidth = '240px';
  public renderInCard = true;
  public searchOnTop = false;
  public listCounts = true;
  public showBarcodeButton = true;

  public selectedDataItems = [];

  public listHeaders: any[] = [];

  @ContentChild(ListActionsDirective, { read: TemplateRef }) listActionsTemplate;
  @ContentChild(ListBannerDirective, { read: TemplateRef }) listBannerTemplate;
  @ContentChild(ListItemDirective, { read: TemplateRef }) listItemTemplate;
  @ContentChild(ListAdditionalContentDirective, { read: TemplateRef }) additionalItemTemplate;
  @ContentChild(ItemFilterDirective, { read: TemplateRef }) itemFilterTemplate;
  @ContentChild(ImportantItemFilterDirective, { read: TemplateRef }) importantItemFilterTemplate;
  @ContentChild(CustomContentDirective, { read: TemplateRef }) customContentTemplate;


  @ViewChild('pagerComponent') pagerComponent : PagerComponent;

//  @Output() onNewItemClicked = new EventEmitter<object>();
  @Output() onDataRequest = new EventEmitter<SearchDataRequest>();
  @Output() onItemSelectionChange = new EventEmitter<object>();
  @Output() onDirectSelection = new EventEmitter<object>();

  // @Output() onItemCheckedChange = new EventEmitter<object>();


  public sortField: string = null;
  public sortAsc = true;
  public fixedTableLayout: boolean;
  public noSpacing = false;

  @Input()
  set options(options: ListOptions) {

    this.setOptions(options);
  }

  public setOptions(options: ListOptions) {
    this.displaySearch = !!options.search;
    
    if (options.showCustomData) {
      this.customData = true;

      this.customHtml = options.customHtml;
    }

    if (options.filterRequired) {
      this.filterRequired = true;
    }
    if (options.actionDropdownWidth) {
      this.actionDropdownWidth = options.actionDropdownWidth;
    }
    if (options.pageSize) {
      this.pageSize = options.pageSize;
    }
    if (options.noItemsText) {
      this.noItemText = options.noItemsText;
    }
    if (options.selectionMode) {
      this.selectionMode = options.selectionMode;
    }

    if (options.listCounts === false) {
      this.listCounts = options.listCounts;
    }

    this.listHeaders = options.headers.filter(h => h !== null);

    if (options.disableSort) {
      this.disableSort = options.disableSort;
    }

    this.sortAsc = !options.sortDesc;

    this.renderInCard = (options.renderInCard !== false);

    this.setDefaultSort();

    if (options.dataRequestMethod) {
      this.dataRequestMethod = options.dataRequestMethod;

    }

    if (options.staticFilters) {
      this.staticFilterValues = {kvp: options.staticFilters};
    }

    this.fixedTableLayout = !!options.fixedTableLayout;
    this.noSpacing = !!options.noSpacing;
    if (options.showBarcodeSearch) {
      this.showBarcodeButton = options.showBarcodeSearch;
    } else {
      this.showBarcodeButton = false;
    }
    if (options.importantFilterValues) {
      this.importantFilterValues.kvp = options.importantFilterValues;
    }
  }

  @Input()
  set Data(data: PagedResult) {
    this.dataItems = data.Records;
    this.totalObjectCount = data.TotalRecords;
    this.resultsCapped = data.ResultCountCapped;

    this.pagerComponent.setObjectCount(data.TotalRecords);
    this.pagerComponent.setPage(this.pageNr);

    if (this.displaySearch) {
      // this.searchComponent.total = data.totalObjectCount;
    }

    this.isLoading = false;
    this.loader.close();
  }

  constructor(private loader: HttpLoaderService,
              private logger: LoggingService,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {

    this.form = new FormGroup({
      SearchText: new FormControl(''),
    });

    this.searchText = '';

  }

  ngAfterViewInit() {
    setTimeout(() => {
      //when a filter is required (i.e. @customerscreen) we do not search oninit.
      if (this.filterRequired === false)
        this.fetchData(1);
    });
  }

  public set staticFilters(val: string) {
    this.staticFilterValues = {kvp: val};
  }

  setSelection(newSelection) {
    this.selectedDataItems = newSelection;
  }


  getColSpan(h) {
    if (h.colspan) {
      return h.colspan;
    }
    return 1;
  }

  getRowClasses(item, odd) {
    let result = 'text-dark '; // 'lh-15 ';

    if (odd === false) {
      result += 'unread ';
    }

    if (this.selectionMode !== ListViewSelectionMode.None) {
      result += 'clickable';
    }

    if (this.isSelected(item)) {
      result += ' selected-list-item';
    }
    return result;
  }

  doRowClick(e, item) {
    if (this.selectionMode === ListViewSelectionMode.None) {
      return;
    }

    if (e.target.nodeName === 'TD' || e.target.nodeName === 'TH' || e.target.type === 'checkbox') {

      if (this.selectionMode === ListViewSelectionMode.MultiSelect) {
        this.multiSelectCheckChanged(item);
      } else {
        this.selectSingle(item);
      }

      // last up, if in single direct select fire the selection event.
      if (this.selectionMode === ListViewSelectionMode.DirectSelect) {
        this.onDirectSelection.emit(item);
      }
    }
  }

  getWidth(h) {
    if (h.width) {
      return h.width + 'px';
    }

    return '';
  }

  checkAllCheckChanged() {
    if (this.selectedDataItems.length === this.dataItems.length) {
      this.selectedDataItems = [];
    } else {
      this.selectedDataItems.push(...this.dataItems);
    }
    this.onItemSelectionChange.emit(this.selectedDataItems);
  }

  multiSelectCheckChanged(item) {
    const index = this.selectedDataItems.findIndex(s => s.Id === item.Id);
    if (index > -1) {
      this.selectedDataItems.splice(index, 1);
    } else {
      this.selectedDataItems.push(item);
    }
    this.onItemSelectionChange.emit(this.selectedDataItems);
  }

  selectSingle(item: any) {
    if (this.selectedDataItems !== null && this.selectedDataItems.length > 0 && this.selectedDataItems[0].Id === item.Id) {
      this.selectedDataItems = [];
    } else {
      this.selectedDataItems = [item];
    }

    this.onItemSelectionChange.emit(this.selectedDataItems);
  }

  setDefaultSort() {
    const result = this.listHeaders.filter(function (h) {
      if (h.isDefaultSort) {
        return h;
      }
    });

    if (result.length > 0) {
      this.sortField = result[0].sortField;
      this.sortAsc = (result[0].isDefaultSort === 'asc');
    }
  }

  sort(h) {
    if (this.disableSort) {
      return;
    }
    if (!h.sortField) {
      return;
    }

    if (this.sortField === h.sortField) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortField = h.sortField;
      this.sortAsc = true;
    }

    // refresh
    this.fetchData(1);
  }

  public refreshData(currentPage = false) {
    this.fetchData(currentPage ? this.pageNr : 1);
  }

  fetchData(pageNr, barcodesearch = false) {
    this.pageNr = pageNr;
    this.isLoading = true;
    // loading until the data is retrieved.
    const sdr: SearchDataRequest = {
      searchText: this.searchText,
      searchFilters: this.filterValues.kvp,
      importantFilters: this.importantFilterValues.kvp,
      staticFilters: this.staticFilterValues.kvp,
      pageNr: pageNr,
      pageSize: this.pageSize,
      sortField: this.sortField,
      sortAsc: this.sortAsc,
      barcodeSearch: barcodesearch
    };

    if (this.dataRequestMethod) {
      let additionalFilters = ListViewHelper.MapSearchDataFiltersToDictionary(sdr);
      
      this.loader.open();

      this.dataRequestMethod(this.searchText, this.pageNr, this.pageSize, this.sortField, this.sortAsc, additionalFilters, null).subscribe((resp : PagedResult) => {
        this.Data = resp;
        this.loader.close();
      });
    } else {
      this.onDataRequest.emit(sdr);
    }

    setTimeout(()=> {
      this.searchPerformed = true;
    });
  }

  isSelected(item): boolean {
    if (!this.selectedDataItems || this.selectedDataItems === null) return false;
    return this.selectedDataItems.filter(f => f !== null && f.Id === item.Id).length > 0;
  }

  allSelected(): boolean {
    let selected = false;
    if (this.dataItems.length > 0) {
      selected = true;
      for (let i = 0; i < this.dataItems.length; i++) {
        if (!this.isSelected(this.dataItems[i])) {
          selected = false;
          break;
        }
      }
    }
    return selected;
  }

  updateFilter(e) {

    e.preventDefault();
    this.searchText = this.form.controls['SearchText'].value;
    this.fetchData(1);
  }

  clearFilter(e) {
    this.form.controls['SearchText'].setValue('');
    this.filterValues = {kvp: {}};
    this.updateFilter(e);
  }

  doPage(pageArgs) {
    this.fetchData(pageArgs.pageNr);
  }

  isVisible(h): boolean {
    if (h.visible != undefined && h.visible != null) {
      return !!h.visible;
    }

    return true;
  }

  getHeaderClasses(h) {
    let result = 'lh-7';
    if (h.class) {
      result += ' ' + h.class;
    }
    return result;
  }

  public reloadEarlierFilter(filterValues: any, pageNr, sortField: string, sortAsc: boolean) {
    this.setFilterData(filterValues);
    this.sortField = sortField;
    this.sortAsc = sortAsc;
    this.fetchData(pageNr);
  }


  public setFilterData(data: any) {
    this.filterValues.kvp = data;
  }

  public setImportantFilterData(data: any) {
    this.importantFilterValues.kvp = data;
  }

  customerPickupBarcode() {
    this.form.controls['SearchText'].setValue('');
    this.filterValues = {kvp: {}};
    this.fetchData(1, true);
  }
}
