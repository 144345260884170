import { TranslateService } from '@ngx-translate/core';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpLoaderService} from '@tymes4-shared';
import {ConfirmService, T4Validators} from '@tymes4-shared';
import {PrintPdfComponent} from '../../print-pdf/print-pdf.component';
import { CurrencyPipe } from '@angular/common';
import * as moment from 'moment';
import { CustomValidators } from '@narik/custom-validators';
import {
  CashRegisterService,
  DirectDebitPaymentInitiationArgs,
  GetDirectDebitFormArgs
} from "../../../api";


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],

})
export class PaymentComponent implements OnInit {

  public form: FormGroup;
  public formIncasso: FormGroup;
  public formOther: FormGroup;
  public amountOpen = 0;
  public paymentSurcharge = 0;
  public amountPaidPin: number;
  public amountPaidCash: number;
  public amountPaidOther: number;
  public confirmReturnRegister: boolean = false;
  public orderIds: number[];

  selectedTabId = 'cash';
  public requiredOrderPayments: any[] = null;
  public canUseDirectDebit: boolean = null;
  public directDebitInstalments: any[];
  public allOrdersHaveEmailsSpecified: boolean = null;
  public instalments = 0;

  validationMessages = [];
  validationMessagesIncasso = {};
  public validationMessageOther = [];

  @ViewChild('tabGroup') tabGroup;
  //tabIndex: number;
  incassoBeingProcessed: boolean = null;
  incassoProcessFullySuccessful: boolean = null;
  amICheckedCheckConfirm: boolean = false;
  amIDisabledCheckConfirm: boolean = false;
  bookingCustomerId: number;
  directDebitFormReceived = false;
  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<PaymentComponent>,
              public cashRegisterService: CashRegisterService,
              public apploader: HttpLoaderService,
              public confirmService: ConfirmService,
              private currencyPipe: CurrencyPipe,
              private dialog: MatDialog,
              private translate: TranslateService) { }

  ngOnInit() {
    this.bookingCustomerId = this.passedData.bookingCustomerId;

    this.apploader.open();
    this.form = new FormGroup({
      Id: new FormControl(''),
      AmountPaidPin: new FormControl('', [Validators.min(0)]),
      AmountPaidCash: new FormControl('', [Validators.min(0)])
    });

    this.formIncasso = new FormGroup({
      Iban: new FormControl('', [T4Validators.validateIban]),
      AccountName: new FormControl('', [Validators.required]),
      PaymentMethod: new FormControl('', [Validators.required]),
    });

    for (let i = 1; i <= 12; i++) {
      const key = `DateEffective${i}`;
      this.formIncasso.addControl(key, new FormControl('', [Validators.required]));
      this.validationMessagesIncasso[key] = [
        { type: 'maxDate', message: '' },
        { type: 'minDate', message: '' },
      ];
    }

    this.formOther = new FormGroup({
      Description: new FormControl('' ),
      AmountPaidOther: new FormControl('', [Validators.min(0)]),
      ConfirmReturnRegister: new FormControl('')
    });

    this.orderIds = this.passedData.payload;

    this.cashRegisterService.getOrderPaymentInfo({ OrderIds: this.orderIds }).subscribe((result) => {

      this.amountOpen = 0;
      this.requiredOrderPayments = result;

      for (const item of result) {
        this.amountOpen += item.Remaining;
      }

      this.apploader.close();
    }, (error) => {
      this.apploader.close();
      this.confirmService.confirm({ title: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.ERROR.TITLE'),
        message: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.ORDER-ERROR.MSG'),
        okonly: true}).subscribe((result) => {
        this.dialogRef.close(false);
      });
    });
  }

  // refreshTabIndex() {
  //   this.tabIndex = this.tabGroup.selectedIndex;
  // }

  closePayment(status) {
    this.dialogRef.close(status);
  }

  doBooking() {
    this.apploader.open();
    if (!this.form.valid) {
      return;
    }

    const amountPaidPin = this.form.controls['AmountPaidPin'].value;
    const amountPaidCash = this.form.controls['AmountPaidCash'].value;

    const bookings = [];
    if (amountPaidPin) {
      bookings.push({
        CashRegisterPaymentType: 1,
        Amount: amountPaidPin
      });
    }
    if (amountPaidCash) {
      bookings.push({
        CashRegisterPaymentType: 2,
        Amount: amountPaidCash
      });
    }
    this.cashRegisterService.bookCashRegisterPayments({ OrderIds: this.orderIds, Bookings: bookings}).subscribe((moneyToReturn : number) => {

      this.apploader.close();

      const clientMoneyToReturn = ((amountPaidPin ? amountPaidPin : 0) + (amountPaidCash ? amountPaidCash : 0)) - this.amountOpen;

      if (moneyToReturn > clientMoneyToReturn) {
        // Tussentijds is de prijs goedkoper geworden. De klant krijgt meer geld terug.
        this.confirmService.confirm({ title: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.MONEY-TO-RETURN.TITLE'),
          message: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.MONEY-TO-RETURN.MSG', {moneyToReturn: this.currencyPipe.transform(moneyToReturn, 'EUR', 'symbol', '1.2-2'), clientMoneyToReturn:  this.currencyPipe.transform(clientMoneyToReturn, 'EUR', 'symbol', '1.2-2')}),
          okonly: true}).subscribe((result) => {
          this.dialogRef.close(true);
        });
      } else {
        this.dialogRef.close(true);
      }
    }, (error) => {
      this.apploader.close();
      this.confirmService.confirm({ title: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.ERROR.TITLE'),
        message: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.PROCESS-ERROR.MSG'),
        okonly: true}).subscribe((result) => {
        this.dialogRef.close(false);
      });
    });
  }

  doBookingIncasso() {
    this.incassoBeingProcessed = true;
    const iban = this.formIncasso.controls['Iban'].value;
    const accountName = this.formIncasso.controls['AccountName'].value;
    const paymentMethod = this.formIncasso.controls['PaymentMethod'].value;

    let isFirst = true;

    for (const ep of this.requiredOrderPayments) {
      if (ep.Remaining <= 0) {
        ep.incassoBookingState = 'successful';
        continue;
      }

      ep.incassoBookingState = 'running';
      const effectiveDates = this.extractEffectiveDates();
      const directDebitPaymentInitiationArgs = {
        OrderId: ep.OrderId,
        DirectDebitInitiation : {
          IBAN: iban,
          AccountName: accountName,
          PaymentMethod: paymentMethod,
          EffectiveDates: effectiveDates
        }
      }

      this.cashRegisterService.initiateCashRegisterDirectDebit(directDebitPaymentInitiationArgs).subscribe((result) => {
        if (result.OK) {
          ep.incassoBookingState = 'successful';
        } else {

          if (isFirst) {

            isFirst = false;

            this.confirmService.confirm({ title: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.DIRECT-DEBIT-FAILED.TITLE'),
              message: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.DIRECT-DEBIT-FAILED.MSG'),
              okonly: true}).subscribe((result) => {});
          }
          ep.incassoBookingState = 'failed';
        }

        this.refreshIncassoBeingProcessedStatus();
      });
    }
    this.refreshIncassoBeingProcessedStatus();
  }

  refreshIncassoBeingProcessedStatus() {
    if (this.requiredOrderPayments.filter(ep => ep.incassoBookingState === 'running').length === 0) {
      this.incassoProcessFullySuccessful = this.requiredOrderPayments.some(ep => ep.incassoBookingState === 'failed');
      this.incassoBeingProcessed = false;
    }
  }

  onTabChange(e) {

    this.selectedTabId = e.selectedTab.id;

    if (e.selectedTab.id === 'directdebit') {

      this.unCheckConfirmOnChange();

      this.apploader.open();
      const anyOrderWithoutCollectPaymentAllowed = this.requiredOrderPayments.filter(op => op.CollectPaymentAllowed === false)[0];
      const anyOrderWithoutEmail = this.requiredOrderPayments.filter(op => op.CustomerHasEmailSpecified === false)[0];

      if (this.requiredOrderPayments.filter(op => op.CollectPaymentAllowed === true).length > 0) {
        this.directDebitInstalments = this.requiredOrderPayments.filter(op => op.CollectPaymentAllowed === true)[0].DirectDebitPaymentMethods;
        if (!this.formIncasso.controls.PaymentMethod.value && this.directDebitInstalments.length > 0) {
          this.formIncasso.controls.PaymentMethod.setValue(this.directDebitInstalments[0].Id);
          this.setDirectDebitPaymentMethod(this.directDebitInstalments[0]);
          this.paymentSurcharge = this.directDebitInstalments[0].Surcharge ? this.directDebitInstalments[0].Surcharge : 0;
        }
      }
      this.canUseDirectDebit = !anyOrderWithoutCollectPaymentAllowed;
      this.allOrdersHaveEmailsSpecified = !anyOrderWithoutEmail;

      this.apploader.close();
    }
    if (e.selectedTab.id === 'cash') {
      this.unCheckConfirmOnChange();
    }
    if (e.selectedTab.id === 'other') {
      this.unCheckConfirmOnChange();
    }
  }

  changePaymentMethod(e) {
    this.setDirectDebitPaymentMethod(e);

    // correct amount to be charged
    this.paymentSurcharge = e.Surcharge ? e.Surcharge : 0;
  }

  setDirectDebitPaymentMethod(paymentMethod: any) {
    this.instalments = paymentMethod.InstalmentCount;

    let minDate = moment(new Date());

    for (let i = 1; i <= 12; i++) {
      const key = `DateEffective${i}`;
      if (paymentMethod.Instalments.length < i) {
        this.formIncasso.controls[key].removeValidators([Validators.required]);
        this.formIncasso.controls[key].setValue(null);
        continue;
      }

      const instalment = paymentMethod.Instalments[i - 1];
      const maxMoment = moment(instalment.Date);
      this.changeMinMaxDateErrorMessage(key, minDate, maxMoment);
      this.formIncasso.controls[key].setValue(instalment.Date);

      const validators = [
        Validators.required,
        CustomValidators.minDate(minDate),
        CustomValidators.maxDate(maxMoment.add(1, 'days'))
      ];
      this.formIncasso.controls[key].setValidators(validators);

      if (!instalment.IsFlexible) {
        this.formIncasso.controls[key].disable();
      } else {
        this.formIncasso.controls[key].enable();
      }

      minDate = maxMoment;
    }
  }

  changeMinMaxDateErrorMessage(formField: string, minDate: moment.Moment, maxDate: moment.Moment) {
    const fieldMessages = this.validationMessagesIncasso[formField];
    if (!fieldMessages) {
      return;
    }

    for (const detail in fieldMessages) {
      if (fieldMessages[detail].type === 'maxDate') {
        fieldMessages[detail].message = this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.VALIDATION.MAXDATE', { date: maxDate.format('DD-MM-YYYY')});
      }
      if (fieldMessages[detail].type === 'minDate') {
        fieldMessages[detail].message = this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.VALIDATION.MINDATE', {date: minDate.format('DD-MM-YYYY')});
      }
    }
  }

  downloadDirectDebitForm() {
    this.apploader.open();
    const iban = this.formIncasso.controls['Iban'].value;
    const accountName = this.formIncasso.controls['AccountName'].value;
    const paymentMethod = this.formIncasso.controls['PaymentMethod'].value;

    const effectiveDates = this.extractEffectiveDates();
    const getDirectDebitFormArgs = {
      BookingCustomerId: this.bookingCustomerId,
      OrderIds: this.orderIds,
      DirectDebitInitiation: {
        IBAN: iban,
        AccountName: accountName,
        PaymentMethod: paymentMethod,
        EffectiveDates: effectiveDates
      }
    }

    this.cashRegisterService.getDirectDebitForm(getDirectDebitFormArgs).subscribe((pdf) => {
      this.directDebitFormReceived = true;
      this.apploader.close();

      const pdfDialogdialogRef: MatDialogRef<any> = this.dialog.open(PrintPdfComponent,
        {
          panelClass: 'full-width-dialog',
          disableClose: false,
          height: '90%',
          width: '90%',
          maxWidth: '98vw',
          data: {
            blob: pdf
          }
        });

      pdfDialogdialogRef.afterClosed().subscribe(data => {});
    });
  }

  doBookingOther() {
    this.apploader.open();
    if (!this.form.valid) {
      return;
    }

    const description = this.formOther.controls['Description'].value;
    const amountPaidOther = this.formOther.controls['AmountPaidOther'].value;
    const confirmReturnRegister = this.confirmReturnRegister;
    const booking = [];

    if (amountPaidOther) {
      booking.push({
        CashRegisterPaymentType: 3,
        Amount: amountPaidOther
      });
    }

    this.cashRegisterService.bookCashRegisterOtherPayments({ OrderIds: this.orderIds, Description: description, Booking: booking, ConfirmReturnRegister: confirmReturnRegister}).subscribe((moneyToReturn) => {
      this.apploader.close();
      this.dialogRef.close(true);
    }, (error) => {
        this.apploader.close();
        this.confirmService.confirm({ title: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.ERROR.TITLE'),
          message: this.translate.instant('SALES.CASH-REGISTER.DIALOG.PAYMENT.CONFIRM.PROCESS-ERROR.MSG'),
          okonly: true}).subscribe((result) => {
          this.dialogRef.close(false);
        });
      });
  }

  checkValue(){
    this.confirmReturnRegister = !this.confirmReturnRegister;
  }
  evalAmount(){
    this.confirmReturnRegister = (this.amountOpen - (this.amountPaidOther?this.amountPaidOther:0) >= 0)? false : this.confirmReturnRegister;
  }

  unCheckConfirmOnChange(){
    this.amICheckedCheckConfirm = false;
    this.amIDisabledCheckConfirm = false;
  }

  checkEval(){
    this.amICheckedCheckConfirm = !this.amICheckedCheckConfirm;
    this.amIDisabledCheckConfirm = this.amICheckedCheckConfirm;
  }

  private extractEffectiveDates() {
    const effectiveDates = [];
    for (let i = 1; i <= 12; i++) {
      const field = this.formIncasso.controls[`DateEffective${i}`];
      if (!field.value) {
        continue;
      }

      const value = field.value;
      effectiveDates.push(value);
    }

    return effectiveDates;
  }
}
