<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<t4-tabs (selected)="onTabChange($event)">

  <t4-tab tabTitle="{{'DIALOGS.CASH-REGISTER.TABTILE.CASH-OR-CARD' | translate}}" id="cash">

    <div class="row">
      <div class="col-6">
        <!-- details to pay -->

        <ng-container *ngIf="requiredOrderPayments !== null">

          <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.HEADER.ORDER' | translate}}</th>
                    <th>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.HEADER.OPEN' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let rp of requiredOrderPayments">
                    <td>{{rp.OrderId}}</td>
                    <td class="text-right">{{rp.Remaining | currency:'EUR'}}</td>
                  </tr>

                  <tr>
                    <td>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.TOTAL' | translate}}</td>
                    <td style="color: black;" class="text-right"><b>{{amountOpen | currency:'EUR'}}</b></td>
                  </tr>
                </tbody>
            </table>
          </div>

        </ng-container>

      </div>

      <div class="col-6">
        <!-- payment form -->
        <form [formGroup]="form" class="form-content">
          <div class="section-wrapper">
            <div class="form-layout">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="form-control-label">{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-PIN.LABEL' | translate}}</label>
                    <input class="form-control text-right" min="0" currencyformat type="number" name="name" [(ngModel)]="amountPaidPin" formControlName="AmountPaidPin" placeholder="{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-PIN.PLACEHOLDER' | translate}}" maxlength="64">
                    <t4-display-validation controlName="AmountPaidPin" [form]="form" [messages]="validationMessages"></t4-display-validation>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="form-control-label">{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-CASH.LABEL' | translate}}</label>
                    <input class="form-control text-right" min="0" currencyformat type="number" name="name" [(ngModel)]="amountPaidCash" formControlName="AmountPaidCash" placeholder="{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-CASH.PLACEHOLDER' | translate}}" maxlength="64">
                    <t4-display-validation controlName="AmountPaidCash" [form]="form" [messages]="validationMessages"></t4-display-validation>
                  </div>
                </div>

              </div>


              <div class="row">
                <div class="col-12">

                  <div class="pos-total border mb-3 py-3 px-4">
                    <div class="row">
                      <div class="col-6">
                        {{'SALES.CASH-REGISTER.DIALOG.PAYMENT.REMAINING.LABEL' | translate}}
                      </div>
                      <div class="col-6 text-right">
                        <b>
                          {{ amountOpen - ((amountPaidPin?amountPaidPin:0) + (amountPaidCash?amountPaidCash:0)) > 0 ? (amountOpen - ((amountPaidPin?amountPaidPin:0) + (amountPaidCash?amountPaidCash:0))  | currency:'EUR') : 0 | currency:'EUR'}}
                        </b>
                      </div>
                    </div>
                  </div>

                  <div class="pos-total border mb-3 py-3 px-4">
                    <div class="row">
                      <div class="col-6">
                        {{'SALES.CASH-REGISTER.DIALOG.PAYMENT.MONEY-BACK.LABEL' | translate}}
                      </div>
                      <div class="col-6  text-right">
                        <b>
                          {{ ((amountPaidPin?amountPaidPin:0) + (amountPaidCash?amountPaidCash:0)) - amountOpen > 0 ? (((amountPaidPin?amountPaidPin:0) + (amountPaidCash?amountPaidCash:0)) - amountOpen | currency:'EUR') : 0 | currency:'EUR'}}
                        </b>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>




  </t4-tab>

  <t4-tab tabTitle="{{'DIALOGS.CASH-REGISTER.TABTILE.DIRECT-DEBIT' | translate}}" id="directdebit">

    <t4-page-warning *ngIf="canUseDirectDebit === false" warning="SALES.CASH-REGISTER.DIALOG.PAYMENT.NO-DIRECT-DEBIT.WARNING"></t4-page-warning>
    <t4-page-warning *ngIf="canUseDirectDebit === true && allOrdersHaveEmailsSpecified === false" warning="SALES.CASH-REGISTER.DIALOG.PAYMENT.EMAIL-SPECIFIED.WARNING"></t4-page-warning>


    <ng-container *ngIf="canUseDirectDebit === true && allOrdersHaveEmailsSpecified === true">
      <form [formGroup]="formIncasso" class="form-content">

        <div class="row">

          <div class="col-6">
            <!-- details to pay -->

            <ng-container *ngIf="requiredOrderPayments !== null">

              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.HEADER.ORDER' | translate}}</th>
                        <th>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.HEADER.OPEN' | translate}}</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rp of requiredOrderPayments">
                        <td>{{rp.OrderId}}</td>
                        <td class="text-right">{{rp.Remaining | currency:'EUR'}}</td>
                        <td>
                          <div [ngSwitch]="rp.incassoBookingState" class="text-right">
                            <i class="fa fa-spinner fa-pulse fa-fw" *ngSwitchCase="'running'"></i>
                            <i class="fa fa-check" *ngSwitchCase="'successful'" style="color: green"></i>
                            <i class="fa fa-times" *ngSwitchCase="'failed'" style="color: red"></i>
                            <!--<i class="fa fa-pause" *ngSwitchDefault style="color: orange"></i>-->
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.TOTAL' | translate}}</td>
                        <td style="color: black;" class="text-right"><b>{{amountOpen | currency:'EUR'}}</b></td>
                      </tr>
                    </tbody>
                </table>
              </div>

            </ng-container>

          </div>

          <div class="col-lg-6">
            <div class="section-wrapper">
              <div class="form-layout">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="form-group">
                      <label class="form-control-label">{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.IBAN.LABEL' | translate}}</label>
                      <input class="form-control"  name="Iban" formControlName="Iban" placeholder="{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.IBAN.PLACEHOLDER' | translate}}" maxlength="64">
                      <t4-display-validation controlName="Iban" [form]="formIncasso" [messages]="validationMessagesIncasso"></t4-display-validation>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-control-label">{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.ACCOUNT-NAME.LABEL' | translate}}</label>
                      <input class="form-control" name="AccountName" formControlName="AccountName" placeholder="{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.ACCOUNT-NAME.PLACEHOLDER' | translate}}" maxlength="64">
                      <t4-display-validation controlName="AccountName" [form]="formIncasso" [messages]="validationMessagesIncasso"></t4-display-validation>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-control-label">{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.TERMS.LABEL' | translate}}</label>
                      <ng-select [items]="directDebitInstalments" bindLabel="InstalmentCount" bindValue="Id" formControlName="PaymentMethod" placeholder="{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.TERMS.PLACEHOLDER' | translate}}"
                                 [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="changePaymentMethod($event)">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                          <ng-container *ngIf="item && item.InstalmentCount">
                            {{ 'SALES.CASH-REGISTER.DIALOG.PAYMENT.INSTALMENT' | translate : {instalmentCount: item.InstalmentCount} }} {{ (item.Surcharge && item.Surcharge > 0) ? ('SALES.CASH-REGISTER.DIALOG.PAYMENT.INSTALMENT-SURCHARGE' | translate : {amount: currencyPipe.transform(item.Surcharge, 'EUR', 'symbol', '1.2-2')}) : '' }}
                          </ng-container>
                        </ng-template>
                      </ng-select>
                      <t4-display-validation controlName="PaymentMethod" [form]="formIncasso" [messages]="validationMessagesIncasso"></t4-display-validation>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="instalments >= 1">
                  <div class="col-lg-6" *ngFor="let item of [].constructor(this.instalments); let i = index">
                    <div class="form-group">
                      <label class="form-control-label">{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.DATE-EFFECTIVE.LABEL' | translate}} {{ i + 1 }}</label>
                      <input class="form-control" [beforeToday]="false" datepicker formControlName="DateEffective{{ i + 1 }}" placeholder="{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.DATE-AFFECTIVE.PLACEHOLDER' | translate}} {{ i + 1 }}" maxlength="64">
                      <t4-display-validation controlName="DateEffective{{ i + 1 }}" [form]="formIncasso" [messages]="validationMessagesIncasso"></t4-display-validation>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="pos-total border mb-3 py-3 px-4">
                      <div class="row">
                        <div class="col-6">
                          {{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-BEING-COLLECTED.LABEL' | translate}}
                        </div>
                        <div class="col-6 text-right">
                          <b>{{amountOpen + paymentSurcharge | currency:'EUR'}}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>


      </form>
    </ng-container>

  </t4-tab>

  <t4-tab tabTitle="{{'DIALOGS.CASH-REGISTER.TABTILE.OTHER' | translate}}" id="other">

    <form [formGroup]="formOther" class="form-content">

      <div class="row">
        <div class="col-6">
          <!-- details to pay -->

          <ng-container *ngIf="requiredOrderPayments !== null">
            <div class="border mb-3 py-3 px-4">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.HEADER.ORDER' | translate}}</th>
                        <th>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.HEADER.OPEN' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rp of requiredOrderPayments">
                        <td>{{rp.OrderId}}</td>
                        <td class="text-right">{{rp.Remaining | currency:'EUR'}}</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.LISTVIEW.TOTAL' | translate}}</td>
                        <td style="color: black;" class="text-right"><b>{{amountOpen | currency:'EUR'}}</b></td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>

          </ng-container>

        </div>

        <div class="col-6">
          <div class="section-wrapper">
            <div class="form-layout">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="form-control-label">{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-OTHER.LABEL' | translate}}</label>
                    <input class="form-control text-right" min="0" currencyformat type="number" name="name" [(ngModel)]="amountPaidOther" formControlName="AmountPaidOther" placeholder="{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-OTHER.PLACEHOLDER' | translate}}" maxlength="64"
                    (change)="unCheckConfirmOnChange(); evalAmount()">
                    <t4-display-validation controlName="AmountPaidOther" [form]="formOther" [messages]="validationMessageOther"></t4-display-validation>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">

                    <div class="pos-total border mb-3 py-3 px-4">
                      <div class="row">
                        <div class="col-6">
                          {{'SALES.CASH-REGISTER.DIALOG.PAYMENT.REMAINING.LABEL' | translate}}
                        </div>
                        <div class="col-6 text-right">
                          <b>
                            {{ amountOpen - (amountPaidOther?amountPaidOther:0) > 0 ? (amountOpen - (amountPaidOther?amountPaidOther:0) | currency:'EUR') : 0 | currency:'EUR'}}
                          </b>
                        </div>
                      </div>
                    </div>

                    <div class="pos-total border mb-3 py-3 px-4">
                      <div class="row">
                        <div class="col-6">
                          {{'SALES.CASH-REGISTER.DIALOG.PAYMENT.MONEY-BACK.LABEL' | translate}}
                        </div>
                        <div class="col-6  text-right">
                          <b>
                            {{ (amountPaidOther?amountPaidOther:0) - amountOpen > 0 ? ((amountPaidOther?amountPaidOther:0) - amountOpen | currency:'EUR') : 0 | currency:'EUR'}}
                          </b>
                        </div>
                      </div>
                    </div>

                </div>
              </div>

              <div class="row">
                <div class="col-12 ">

                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="ConfirmReturnRegister"
                    (change)="checkValue(); unCheckConfirmOnChange()" id="ConfirmReturnRegister"
                    [checked]="confirmReturnRegister"
                    [disabled]="((amountPaidOther?amountPaidOther:0) - amountOpen <= 0)">
                    <label class="form-check-label" for="ConfirmReturnRegister">
                      {{'SALES.CASH-REGISTER.DIALOG.PAYMENT.REFUNDED-AT-CASH-REGISTER.LABEL' | translate}}
                    </label>
                    <t4-display-validation controlName="ConfirmReturnRegister" [form]="formOther" ></t4-display-validation>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 pt-2">
                      <div class="form-group">
                        <label class="form-control-label">{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-OTHER.DESCRIPTION.LABEL' | translate}} </label>
                        <input class="form-control" name="Description" formControlName="Description" placeholder="{{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-OTHER.DESCRIPTION.PLACEHOLDER' | translate}}" maxlength="256">
                        <t4-display-validation controlName="Description" [form]="formOther" ></t4-display-validation>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 pb-4">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="CheckConfirm" [disabled]="(amountPaidOther?amountPaidOther:0) <= 0" [checked]="amICheckedCheckConfirm" (change)="checkEval()">
                        <label class="form-check-label" for="CheckConfirm">
                          {{'SALES.CASH-REGISTER.DIALOG.PAYMENT.AMOUNT-PAID-OTHER.PAYMENT-VALIDATED.LABEL' | translate}}
                        </label>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>



        </div>



      </div>
    </form>




  </t4-tab>

</t4-tabs>

<div class="form-layout-footer row">

  <ng-container *ngIf="selectedTabId == 'cash'">
    <div class="col-12">
      <button (click)="doBooking()" [disabled]="(((amountPaidPin?amountPaidPin:0) + (amountPaidCash?amountPaidCash:0)) - amountOpen < 0) || form.valid === false"class="btn btn-block btn-primary bd-0">
        <i class="far fa-save"></i><span>{{'BOX-OFFICE.CASH-REGISTER/PAYMENT.PAYMENT-COMPONENT.FORM.BUTTON.CONFIRM-PAYMENT-CASH-OR-CARD' | translate}}</span>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedTabId == 'other'">
    <div class="col-12">
      <button (click)="doBookingOther()" [disabled]="!amIDisabledCheckConfirm" class="btn btn-block btn-primary bd-0" id='confirmPaymentOther'>
        <i class="far fa-save"></i><span>{{'BOX-OFFICE.CASH-REGISTER/PAYMENT.PAYMENT-COMPONENT.FORM.BUTTON.CONFIRM-PAYMENT-OTHER-METHODS' | translate}}</span>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedTabId == 'directdebit'">

    <ng-container *ngIf="incassoBeingProcessed === null">

      <div class="col-6">
        <button (click)="doBookingIncasso()" [disabled]="formIncasso.valid === false || !directDebitFormReceived"  class="btn btn-block btn-primary bd-0">
          <i class="far fa-save"></i><span>{{'BOX-OFFICE.CASH-REGISTER/PAYMENT.PAYMENT-COMPONENT.FORM.BUTTON.CONFIRM-PAYMENT-DIRECT-DEBIT' | translate}}</span>
        </button>
      </div>

      <div class="col-6">
        <button (click)="downloadDirectDebitForm()" [disabled]="formIncasso.valid === false"  class="btn btn-block btn-primary bd-0">
          <i class="far fa-file-alt"></i><span>{{'BOX-OFFICE.CASH-REGISTER/PAYMENT.PAYMENT-COMPONENT.FORM.BUTTON.DOWNLOAD-FORM' | translate}}</span>
        </button>
      </div>

    </ng-container>

    <ng-container *ngIf="incassoBeingProcessed !== null">

      <div fxFlex="420px" class="text-right" >

        <div class="col-12">
          <button (click)="closePayment(true)" [disabled]="incassoBeingProcessed || !incassoProcessFullySuccessful"  class="btn btn-block btn-primary bd-0">
            <i class="far fa-save"></i>  <span>{{'BOX-OFFICE.CASH-REGISTER/PAYMENT.PAYMENT-COMPONENT.FORM.BUTTON.FINNISH-DIRECT-DEBIT' | translate}}</span>
          </button>
        </div>

      </div>

    </ng-container>


  </ng-container>


</div>
