<div [class.border]="renderInCard === true" class="rounded bg-white" [class.email-wrapper]="noSpacing === false">
  <div class="row no-gutters justify-content-center">

    <!-- END FILTER PART -->
    <div class="col-lg-12">
      <div class="email-right-column" [class.p-4]="noSpacing === false" [class.p-xl-5]="noSpacing === false">
        <!-- Email Right Header -->
        <div class="email-right-header"  *ngIf="displaySearch || importantItemFilterTemplate || listActionsTemplate">
          <!-- head left option -->

          <form [formGroup]="form">
            <div class="head-left-options">

              <ng-container *ngIf="displaySearch">
                <div class="form-group mg-b-0">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bd-t-0 bd-l-0"><i class="fas fa-search"></i> </span>
                    </div>
                    <input formControlName="SearchText" (keydown.enter)="updateFilter($event)" type="text" 
                    	   style="background-color: #f5f6fa;" class="form-control bd-t-0 bd-r-0 bd-l-0" 
                    	    placeholder="{{'T4.LIST-VIEW.SEARCHINLIST' | translate}}">
                  </div>
                </div>

              </ng-container>

              <div *ngIf="importantItemFilterTemplate">
                <ng-container *ngTemplateOutlet="importantItemFilterTemplate; context: importantFilterValues">
                </ng-container>
              </div>


            </div>
          </form>
          <!-- head right option -->
          <div class="head-right-options">

            <ng-container *ngTemplateOutlet="listActionsTemplate">
            </ng-container>

          </div>
        </div>

        <div *ngIf="itemFilterTemplate" class="listview-filter">
          <ng-container *ngTemplateOutlet="itemFilterTemplate; context: filterValues">
          </ng-container>
          <div class="row">
            <div class="col-lg-12" style="padding: 10px 20px 0px 0px;">
              <div class="row">
                <div class="col-lg-4 text-left pl-6">
                  <button *ngIf="showBarcodeButton" class="btn btn-secondary border btn-small" type="button" (click)="customerPickupBarcode()">
                    <i class="fas fa-barcode"></i>
                    <span>{{ 'BTN.SEARCH.BARCODE' | translate }}</span>
                  </button>
                </div>
                <div class="col-lg-8 text-right">
                  <button (click)="clearFilter($event)"  class="btn btn-warning border btn-small mr-1" type="button"><i class="fas fa-undo"></i>{{'GENERIC.BUTTON.EMPTY' | translate}}</button>
                  <button (click)="updateFilter($event)" class="btn btn-primary border btn-small" type="button"><i class="fas fa-search"></i>{{'BTN.APPLY' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="listBannerTemplate">
          <ng-container *ngTemplateOutlet="listBannerTemplate"></ng-container>
        </ng-container>

        <div class=" border-top-0 rounded table-responsive" [style.overflow]="fixedTableLayout ? 'auto':'inherit'">
          <table class="table mb-0 table-hover table-email" [style.tableLayout]="fixedTableLayout ? 'fixed':'inherit'">

            <thead>

              <tr class="list-head">
                <th scope="col" style="width:26px; vertical-align: middle;" *ngIf="this.selectionMode === 3 || this.selectionMode === 4"></th>
                <ng-container *ngFor="let h of listHeaders">
                  <th scope="col" *ngIf="isVisible(h)" [class]="getHeaderClasses(h)" [attr.colspan]='getColSpan(h)'
                    [style.width]='getWidth(h)' [style.overflow]="h.overflowHidden ? 'hidden':'inherit'">
                    <span [class.clickable]="h.sortField !== null && !disableSort" (click)="sort(h)">{{h.label | translate}}</span>
                    <i *ngIf="sortField === h.sortField && sortAsc === true && !disableSort" class="sort-by-asc"></i>
                    <i *ngIf="sortField === h.sortField && sortAsc === false && !disableSort" class="sort-by-desc"></i>
                  </th>
                </ng-container>
              </tr>

            </thead>

            <tbody>

                <ng-container *ngTemplateOutlet="customContentTemplate;"></ng-container>
              <ng-container *ngIf="dataItems !== null && dataItems !== undefined && dataItems.length > 0; else noItems">

                <ng-container *ngTemplateOutlet="additionalItemTemplate;"></ng-container>

                <tr *ngFor="let item of dataItems; let odd = odd" [class]="getRowClasses(item, odd)" (click)="doRowClick($event, item)">
                  <td *ngIf="this.selectionMode === 3 || this.selectionMode === 4" style="width:26px;"><div style="padding-top: 4px;"><input type="checkbox" [checked]="isSelected(item)"></div> </td>
                  <ng-container *ngTemplateOutlet="listItemTemplate; context: {$implicit: item}"></ng-container>
                </tr>

              </ng-container>

              <ng-template #noItems>
                <tr>
                  <!-- <td *ngIf="multiSelectMode"></td> -->
                  <td *ngIf="searchPerformed === true" [attr.colspan]="listHeaders.length " [innerHTML]="noItemText | translate"></td>
                  <td *ngIf="searchPerformed === false" [attr.colspan]="listHeaders.length " [innerHTML]="'LISTVIEW.STARTSEARCH' | translate"></td>
                </tr>
              </ng-template>

            </tbody>
          </table>
        </div>

        <t4-pager [pageSize]="pageSize" [listCounts]="listCounts" [selectCount]="null"
            (onPage)="doPage($event)" [resultsCapped]="resultsCapped" #pagerComponent></t4-pager>

      </div>
    </div>
  </div>
</div>
