import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppCurrencyPipe } from 'app/shared/pipes/currency.pipe';


@Component({
  selector: 'app-edit-price-form',
  templateUrl: './edit-price-form.component.html'
})

export class EditPriceFormComponent implements OnInit {
  public priceLineComponentSelections = [];
  public isReadOnly = false;
  public allowEmptyRate = false;
  public clearComponentPrice = false;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              private currencyPipe: AppCurrencyPipe,
              public dialogRef: MatDialogRef<EditPriceFormComponent>) { }

  public logoimagecontext: CanvasRenderingContext2D;
  public overviewimagecontext: CanvasRenderingContext2D;

  ngOnInit() {
    this.priceLineComponentSelections = this.passedData.priceLineComponentSelections;
    this.isReadOnly = this.passedData.readOnly;

    //format the prices
    for (let p of this.priceLineComponentSelections) {
      p.AmountExVAT = p.AmountExVAT === 0 ? '0.00' : this.currencyPipe.transform(p.AmountExVAT);
      p.AmountInVAT = p.AmountInVAT === 0 ? '0.00' : this.currencyPipe.transform(p.AmountInVAT);

      if(p.AmountExVAT === '')
      {
        p.AmountExVAT = null;
      }
      if(p.AmountInVAT === '')
      {
        p.AmountInVAT = null;
      }
    }

    if (this.passedData.allowEmptyRate && this.passedData.allowEmptyRate === true) {
      this.allowEmptyRate = true;
    }

    this.clearComponentPrice = !!this.passedData.clearComponentPrice;

    this.priceLineComponentSelections = this.passedData.priceLineComponentSelections;
  }

  onAmountExVATChanged(event, ps) {
    if (event.target.value === '') {
      ps.AmountExVAT = null;
      ps.VAT = 0;
      ps.AmountInVAT = null;
    } else {
      ps.AmountExVAT = +event.target.value;
      ps.VAT = Math.round(ps.AmountExVAT * (ps.VATPercentage / 100) * 100) / 100;
      ps.AmountInVAT = ps.AmountExVAT + ps.VAT;
    }
  }

  onAmountInVATChanged(event, ps) {
    if (event.target.value === '') {
      ps.AmountExVAT = null;
      ps.VAT = 0;
      ps.AmountInVAT = null;
    } else {
      ps.AmountInVAT = +event.target.value;
      ps.VAT = Math.round(((ps.AmountInVAT / (1 + (ps.VATPercentage / 100))) * (ps.VATPercentage / 100)) * 100) / 100;
      ps.AmountExVAT = ps.AmountInVAT - ps.VAT;
    }
  }

  removePrice(ps) {
    ps.AmountExVAT = null;
    ps.AmountInVAT = null;
    ps.VAT = 0;
  }

  submit() {
    this.dialogRef.close(this.priceLineComponentSelections);
  }
}
