import { ListViewComponent } from './../../list-view/list-view.component';
import { PagedResult } from './../../../models/list-view/pagedResult';
import { ListViewSelectionMode } from './../../../enums/list-view-selection-mode.enum';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";

@Component({
    selector: 't4-discount-lines',
    templateUrl: './discount-lines.component.html'
})
export class DiscountLinesComponent implements OnInit, AfterViewInit {

    @Input() public discountLines: Array<any>;

    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7 ,
        noItemsText: 'T4.ORDERDETAILS.DISCOUNTLINES.NODISCOUNT',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        noSpacing: true,
        renderInCard: false,
        headers: [
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.DISCOUNT', hideOn: null },
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.AMOUNT', hideOn: null, width: 80, class: 'text-right'}
        ]
      };

    public discountLinesLength;
    public pagedDiscountLines;

    constructor() { }

    doPage($event) {
        this.pagedDiscountLines = this.discountLines.slice(($event.pageNr - 1) * $event.pageSize, ($event.pageNr) * $event.pageSize);
        
        let d : PagedResult = {
            TotalRecords: this.discountLinesLength,
            Records: this.pagedDiscountLines
        };

        this.listVw.Data = d;
    }

    ngOnInit(): void {
        this.discountLinesLength = this.discountLines.length;
    }

    ngAfterViewInit() {
    
    }

}
