import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpLoaderService } from '@tymes4-shared';
import { OrderService, OrderFailureService } from '../../api';

@Component({
  selector: 'app-payment-overview',
  templateUrl: './payment-overview.component.html',
  styleUrls: ['./payment-overview.component.scss']
})
export class PaymentOverviewComponent implements OnInit {

  constructor(private apploader: HttpLoaderService,
              private orderService : OrderService,
              private orderFailureService : OrderFailureService,
              private translate: TranslateService) { }

  showPaymentDetails = false;

  orderId: number = null;
  orderFailureId: number = null;

  pagedHistoryLines = [];
  lastUsedPageHistory = null;
  orderPaymentHistory = null;

  @ViewChild('historyPagerComponent') historyPagerComponent;

  ngOnInit() {
  }

  doHistoryPage(pageArgs) {
    this.setHistoryPage(pageArgs.pageNr);
  }


  setHistoryPage(pageNr: number) {
    this.lastUsedPageHistory = pageNr;
    let length = this.orderPaymentHistory.length; //we need a copy
    this.historyPagerComponent.setObjectCount(length);
    this.pagedHistoryLines = this.orderPaymentHistory.slice((pageNr - 1) * this.historyPagerComponent.pageSize, (pageNr) * this.historyPagerComponent.pageSize);
    this.historyPagerComponent.setPage (pageNr);
  }

  getPaymentStatusText(status) {
    switch (status){
      case 1: return this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-STATUS.OK');
      case 2: return this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-STATUS.CANCELED');
      case 3: return this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-STATUS.FAILED');
      case 4: return this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-STATUS.DENIED');
      case 5: return this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-STATUS.INPROGRESS');
      case 6: return this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-STATUS.DIRECTDEBIT');
    }
  }

  loadPaymentHistoryForOrder(orderId) {
    this.orderId = orderId;
    this.orderFailureId = null;
    this.loadPaymentHistoryInternal();
  }

  loadPaymentHistoryForOrderFailure(orderFailureId) {
    this.orderFailureId = orderFailureId;
    this.orderId = null;
    this.loadPaymentHistoryInternal();
  }


  loadPaymentHistoryInternal() {

    this.apploader.open();

    if (this.orderId !== null) {

      //user clicked on the paymenthistory but that has not yet been loaded
      this.orderService.getPaymentHistory(this.orderId, this.showPaymentDetails).subscribe((data:any) => {
        this.orderPaymentHistory = data;
        this.setHistoryPage(1);
        this.apploader.close();
      });
    }
    else {
      //user clicked on the paymenthistory but that has not yet been loaded
      this.orderFailureService.getOrderFailurePaymentHistory(this.orderFailureId, this.showPaymentDetails).subscribe((data:any) => {
        this.orderPaymentHistory = data;
        this.setHistoryPage(1);
        this.apploader.close();
      });
    }


  }

  onShowDetailsChange(e) {

    this.showPaymentDetails = e.target.checked;
    this.loadPaymentHistoryInternal();

  }



}
